@import 'tippy.js/dist/tippy.css';
@import 'tabulator-tables/dist/css/tabulator_bootstrap5.min.css';

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

html {
    height: -webkit-fill-available;
}

.tab-content {
    padding: 15px 0;
}

/* Form placeholder */
.form-control::placeholder {
    opacity: 0.43 !important;
}

tr[ng-reflect-router-link] {
    cursor: pointer;
}

li[ng-reflect-router-link] {
    cursor: pointer;
}

img.avatar {
    text-align: center;
    margin: 10px auto 25px auto;
    border-radius: 100%;
    height: 150px;
    border: 1px solid #aaa;
}

.img-xs,
.img-sm,
.img-md,
.img-lg,
.img-round-edge {
    border-radius: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 2px;
    position: relative;
    &:before {
        content: '';
        float: left;
        padding-top: 100%; /* initial ratio of 1:1*/
    }
}
.img-banner-xs,
.img-banner-sm,
.img-banner-md,
.img-banner-lg {
    border-radius: 10%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 2px;
}

.img-xs,
.img-banner-xs {
    height: 32px;
    width: 32px;
}
.img-sm,
.img-banner-sm {
    height: 48px;
    width: 48px;
}
.img-md,
.img-banner-md {
    height: 64px;
    width: 64px;
}
.img-lg,
.img-banner-lg {
    height: 128px;
    width: 128px;
}
.img-xl,
.img-banner-xl {
    height: 256px;
    width: 256px;
}

.img-banner-xs {
    width: 116px;
}
.img-banner-sm {
    width: 174px;
}
.img-banner-md {
    width: 232px;
}
.img-banner-lg {
    width: 464px;
}
.img-banner-xl {
    width: 928px;
}

.rating-clickable {
    cursor: pointer;

    &:hover {
        background-color: var(--light);
    }
}

/* CkEditor */
ckeditor {
    width: 100%;

    .ck-editor__editable_inline {
        min-height: 350px;
    }
}

/* Aspect ratio */
.aspect-ratio {
    position: relative;

    > * {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
    }

    &.aspect-ratio-16-9 {
        overflow: hidden;
        height: 0;
        padding-top: 56.25%;
    }
    &.aspect-ratio-1-1 {
        overflow: hidden;
        height: 0;
        padding-top: 100%;
    }
}
